import React from 'react';
import contentfulClient from './createContentfulClient';
import { CONTENTFUL_COMPONENTS, BUTTON_BG_LEGACY } from '@/utils/constants';
import ReactHTMLParser from 'react-html-parser';
import ReactDOMServer from 'react-dom/server';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { marked } from 'marked';

const ImportantInfoRTConfig = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <>{children}</>
  }
};
const getStaticMarkup = (CFRichTextObj, config) =>
  CFRichTextObj
    ? documentToReactComponents(CFRichTextObj, ).map(item => ReactDOMServer.renderToStaticMarkup(item))
    : undefined;

function getFieldsData(data) {
  if (Array.isArray(data)) {
    return data.map(getFieldsData);
  }

  if (typeof data === 'object' && data !== null) {
    if (data.fields) {
      const fieldsWithId = {
        ...data.fields,
        id: data.sys.id,
        contentType: data.sys.contentType
      };
      return getFieldsData(fieldsWithId);
    } else {
      const result = {};
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          result[key] = getFieldsData(data[key]);
        }
      }
      return result;
    }
  }
  return data;
}

const getPageDataByPath = async path => {
  if (path.endsWith('.html') || path.endsWith('.html/')) {
    path = path.replace(/\/$/, '');
  } else if (!path.endsWith('/')) {
    path += '/';
  }

  try {
    const response = await contentfulClient.getEntries({
      'fields.path': path,
      content_type: 'page',
      include: 6
    });
    return getFieldsData(response.items);
  } catch (error) {
    console.log('Error fetching contentful entries', error);
    return [];
  }
};

const formatDataFromContentful = pageData => {
  const { template, body, ...rest } = pageData || {};
  const { header, footer, alert, ...restOfTemplate } = template || {};

  //format contentful navTreeData to match header component
  const updatedHeader = header && {
    ...header,
    navTreeData: header.navTreeData && {
      ...header.navTreeData,
      items: header.navTreeData.items?.map(navGroup => ({
        ...navGroup,
        items: navGroup.items?.map(navSubGroup => navSubGroup.items)
      }))
    }
  };

  //format contentful footer data to match Footer component
  const updatedFooter = {
    ...footer,
    finePrint: footer?.finePrint && marked(footer?.finePrint),
    footerLinks: footer?.footerLinks?.links,
    appLinks:
      (footer?.appLinks && {
        downloadLabel:
          footer?.appLinks?.header && marked(footer?.appLinks?.header),
        appleLink: footer?.appLinks?.links?.filter(
          link => link?.label === 'Apple Link'
        )?.[0]?.link,
        googleLink: footer?.appLinks?.links?.filter(
          link => link?.label === 'Google Link'
        )?.[0]?.link
      }) ||
      undefined,
    socialLinks: footer?.socialLinks?.links,
    siteLinks: footer?.siteLinks?.map(siteLink => ({
      header: siteLink?.header,
      menuItems: siteLink?.links?.map(link => ({
        label: link?.label,
        link: link?.link
      }))
    })),
    cardData: footer?.cardDataText
      ? {
          text: footer?.cardDataText,
          href: footer?.cardDataUrl,
          img: footer?.cardDataImage?.file?.url
        }
      : undefined,
    hideSocials: !footer.socialLinks.links,
    hideSiteLinks: !footer.siteLinks
  };

  //format contentful components
  const updatedBody = body?.reduce(
    (acc, item) => {
      const {
        contentType: {
          sys: { id }
        }
      } = item;
      switch (id) {
        case CONTENTFUL_COMPONENTS.ALERT:
          acc.alertData = acc.alertData || item;
          break;
        case CONTENTFUL_COMPONENTS.HERO_BANNER_CAROUSEL:
          acc.heroBannerData.push(item);
          break;
        case CONTENTFUL_COMPONENTS.INTEREST_FREE_OFFERS:
          acc.interestFreeOffersData.push(item);
          break;
        case CONTENTFUL_COMPONENTS.FEATURE_SLIDER:
          const updatedItem = {
            heading: marked(item.heading || ''),
            description: ReactHTMLParser(marked(item.description || '')),
            contentAlign: item.contentAlign || 'center',
            featureCards: item.featureCards?.map(card => ({
              ...card,
              text: marked(card.text || '')
            }))
          };
          acc.featureSliderData.push(updatedItem);
          break;
        case CONTENTFUL_COMPONENTS.PROMO_BANNER:
          acc.promoBannerData.push(item);
          break;
        case CONTENTFUL_COMPONENTS.OFFER_BANNER:
          acc.offerBannerData.push(item);
          break;
        case CONTENTFUL_COMPONENTS.FEATURE_COLUMN:
          acc.featureColsData.push(item);
          break;
        case CONTENTFUL_COMPONENTS.RATES_AND_FEES:
          acc.ratesAndFeesData.push(item);
          break;
        case CONTENTFUL_COMPONENTS.IN_PAGE_NAV:
          const { id, ...rest } = item;
          const updatedPageNav = {
            ...rest,
            contentfulID: id,
            ctaType: rest?.ctaType ? BUTTON_BG_LEGACY[rest.ctaType] : 'primary'
          };
          acc.inPageNavData.push(updatedPageNav);
          break;
        case CONTENTFUL_COMPONENTS.STANDALONE_TEXT:
          acc.standaloneTextData.push(item);
          break;
        case CONTENTFUL_COMPONENTS.SECONDARY_NAV:
          const navItems = item.navItems.map(item => ({
            ...item,
            href: item.link
          }));
          acc.secondaryNavData = {
            ...item,
            navItems
          };
          break;
        case CONTENTFUL_COMPONENTS.IMPORTANT_INFO:
          const {
            title,
            icon,
            importantInformationSectionOne,
            importantInformationSectionTwo,
            additionalPaymentsDisclaimerOne,
            additionalPaymentsDisclaimerTwo
          } = item;

          const updatedImportantInfo = {
            title,
            icon,
            contentfulID: item?.id,
            data: {
              content: {
                importantInformationSectionOne: getStaticMarkup(
                  importantInformationSectionOne,
                  ImportantInfoRTConfig
                ),
                importantInformationSectionTwo: getStaticMarkup(
                  importantInformationSectionTwo,
                  ImportantInfoRTConfig
                ),
                additionalPaymentsDisclaimerOne: getStaticMarkup(
                  additionalPaymentsDisclaimerOne,
                  ImportantInfoRTConfig
                ),
                additionalPaymentsDisclaimerTwo: getStaticMarkup(
                  additionalPaymentsDisclaimerTwo,
                  ImportantInfoRTConfig
                )
              }
            },
            sectionOneColummClasses: importantInformationSectionTwo
              ? 'col-10 offset-1 col-lg-5 offset-lg-1'
              : 'col-10 offset-1',
            sectionTwoColummClasses: importantInformationSectionTwo
              ? 'col-10 offset-1 col-lg-5 offset-lg-0'
              : ''
          };
          acc.importantInfoData.push(updatedImportantInfo);
          break;
        case CONTENTFUL_COMPONENTS.FAQ:
          let updatedFaq = {};
          if (item?.data?.length === 1) {
            updatedFaq = {
              contentfulID: item?.id,
              title: item?.title,
              description: item?.description,
              cta: item?.cta,
              data: item?.data?.[0]?.qa?.map(({ q, a, ...rest }) => ({
                ...rest,
                title: q,
                content: getStaticMarkup(a)?.join('')
              }))
            };
          } else {
            updatedFaq = {
              contentfulID: item?.id,
              title: item?.title,
              description: item?.description,
              cta: item?.cta,
              data: item?.data?.map(({ qa, ...rest }) => ({
                ...rest,
                qa: qa?.map(({ q, a, ...rest }) => ({
                  ...rest,
                  q,
                  a: getStaticMarkup(a)?.join('')
                }))
              }))
            };
          }
          acc.faqData.push(updatedFaq);
          break;
        case CONTENTFUL_COMPONENTS.RETAILER_PARTNER_LIST:
          acc.partnersListData.push(item);
          break;

          default:
          break;
      }
      return acc;
    },
    {
      alertData: null,
      heroBannerData: [],
      interestFreeOffersData: [],
      featureSliderData: [],
      promoBannerData: [],
      offerBannerData: [],
      featureColsData: [],
      ratesAndFeesData: [],
      inPageNavData: [],
      standaloneTextData: [],
      secondaryNavData: null,
      importantInfoData: [],
      faqData: [],
      partnersListData: []
    }
  ) || {
    alertData: null,
    heroBannerData: [],
    interestFreeOffersData: [],
    featureSliderData: [],
    promoBannerData: [],
    offerBannerData: [],
    featureColsData: [],
    ratesAndFeesData: [],
    inPageNavData: [],
    standaloneTextData: [],
    secondaryNavData: null,
    importantInfoData: [],
    faqData: [],
    partnersListData: []
  };

  return {
    ...rest,
    body,
    updatedBody: {
      ...updatedBody,
      alertData: updatedBody.alertData || alert
    },
    template: {
      ...restOfTemplate,
      header: updatedHeader,
      footer: updatedFooter
    }
  };
};

export { getPageDataByPath, formatDataFromContentful };
